/**
 * These are the errors which are returned by the API layer.
 *
 * Every method of the API layer which returns a Deferred must reject with one of these values
 */
export const API_ERROR_BROKEN_SERVER_RESPONSE = 'api-error-broken-server-response';
export const API_ERROR_CONNECTION_PROBLEM = 'api-error-connection-problem';
export const API_ERROR_ERROR_TRY_RELOAD = 'api-error-try-reload';
export const API_ERROR_EXCEPTION = 'api-error-EXCEPTION'; // used when an unexpected exception was thrown in the api layer
export const API_ERROR_INVALID_API_KEY = 'api-error-invalid-api-key';
export const API_ERROR_INVALID_CONVERSION_OPTIONS = 'api-error-invalid-conversion-options';
export const API_ERROR_JOB_CAN_NOT_BE_MODIFIED = 'api-error-job-can-not-be-mofified';
export const API_ERROR_JOB_NOT_FOUND = 'api-error-job-not-found';
export const API_ERROR_LIMITS_INPUT_TOO_LARGE = 'api-error-file-too-large';
export const API_ERROR_LIMITS_TOO_MANY_INPUTS = 'uploader-error-too-many-inputs';
export const API_ERROR_LIMITS_YOUTUBE_FORBIDDEN = 'api-error-youtube-forbidden';
export const API_ERROR_NO_UPLOAD_SERVER = 'uploader-error-no-upload-server';
export const API_ERROR_OVERUSE = 'api-error-overuse';
export const API_ERROR_PAYMENT_REQUIRED = 'api-error-payment-required';
export const API_ERROR_UNEXPECTED_JOB_STATUS = 'api-error-unexpected-job-status';
export const API_ERROR_TOO_MANY_RETRIES = 'api-error-too-many-retries';
export const API_ERROR_UNKNOWN = 'api-error-unknown'; // used when something unknown happened
export const API_ERROR_NOT_IMPLEMENTED = 'api-error-not-implemented';
export const API_ERROR_JOB_CANCEL = 'api-error-job-cancel';
export const API_ERROR_URL_TOO_LONG = 'api-error-url-too-long';
export const API_ERROR_JOB_CONVERSIONS_BLOCKED = 'api-error-job-conversions-blocked';

export const API_ERRORS = [
    API_ERROR_BROKEN_SERVER_RESPONSE,
    API_ERROR_CONNECTION_PROBLEM,
    API_ERROR_ERROR_TRY_RELOAD,
    API_ERROR_EXCEPTION,
    API_ERROR_INVALID_API_KEY,
    API_ERROR_INVALID_CONVERSION_OPTIONS,
    API_ERROR_JOB_CAN_NOT_BE_MODIFIED,
    API_ERROR_JOB_NOT_FOUND,
    API_ERROR_LIMITS_INPUT_TOO_LARGE,
    API_ERROR_LIMITS_TOO_MANY_INPUTS,
    API_ERROR_LIMITS_YOUTUBE_FORBIDDEN,
    API_ERROR_NO_UPLOAD_SERVER,
    API_ERROR_OVERUSE,
    API_ERROR_PAYMENT_REQUIRED,
    API_ERROR_UNEXPECTED_JOB_STATUS,
    API_ERROR_TOO_MANY_RETRIES,
    API_ERROR_UNKNOWN,
    API_ERROR_NOT_IMPLEMENTED,
    API_ERROR_URL_TOO_LONG,
    API_ERROR_JOB_CANCEL,
    API_ERROR_JOB_CONVERSIONS_BLOCKED,
];
