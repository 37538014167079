import {
    LIMIT_FEATURE_IS_PREMIUM,
    LIMIT_FEATURE_TOO_MANY_JOBS,
    LIMIT_GENERIC,
    LIMIT_GENERIC_CONVERSION_OPTION_IS_PREMIUM,
    LIMIT_GLOBAL_TOO_MANY_JOBS,
    LIMIT_GLOBAL_TOO_MANY_CONCURRENT_CONVERSIONS,
    LIMIT_OCR_IS_PREMIUM, LIMIT_CREDITS_CONSUMED, LIMIT_JOB_CONVERSIONS_BLOCKED,
} from '../limits';
import { testIsBoolean, testIsNonEmptyString, testIsNumber } from '../../../lib/test-and-assert/test-base';

class LimitData {
    /**
     * @type {?String}
     */
    type = null;

    /**
     * @type {?Number}
     */
    currentTtl = null;

    /**
     * @type {?Number}
     */
    ttl = null;

    /**
     * @type {?Boolean}
     */
    hit = null;

    /**
     * @type {?String}
     */
    ident = null;

    /**
     * @type {?String}
     */
    triggeredBy = null;

    /**
     * @type {?String}
     */
    constraintType = null;

    /**
     * @type {?Number}
     */
    currentValue = null;

    /**
     * @type {?Number}
     */
    maxValue = null;

    /**
     * @type {?Number}
     */
    expiresAt = null;

    constructor(type) {
        this.type = type;
    }

    /**
     * @param {RawConstraintStatus} constraintStatus
     */
    static createFromConstraintStatus(constraintStatus) {
        const limitData = new LimitData();

        let type = LIMIT_GENERIC;

        if (constraintStatus.type === 'conversion_option') {
            type = LIMIT_GENERIC_CONVERSION_OPTION_IS_PREMIUM;

            if (constraintStatus.triggered_by === 'ocr') {
                type = LIMIT_OCR_IS_PREMIUM;
            }
        } else if (constraintStatus.type === 'conversion_operation') {
            type = LIMIT_FEATURE_TOO_MANY_JOBS;

            if (testIsNumber(constraintStatus.max_value) && constraintStatus.max_value === 0) {
                type = LIMIT_FEATURE_IS_PREMIUM;
            }
        } else if (constraintStatus.type === 'concurrent_conversions') {
            type = LIMIT_GLOBAL_TOO_MANY_CONCURRENT_CONVERSIONS;
        } else if (constraintStatus.type === 'rate_limit') {
            type = LIMIT_GLOBAL_TOO_MANY_JOBS;
        } else if (constraintStatus.type === 'credits_consumed') {
            type = LIMIT_CREDITS_CONSUMED;
        } else if (constraintStatus.type === 'limit_job_conversions_blocked') {
            type = LIMIT_JOB_CONVERSIONS_BLOCKED;
        }

        limitData.type = type;
        limitData.constraintType = constraintStatus.type;

        if (testIsNonEmptyString(constraintStatus.ident)) {
            limitData.ident = constraintStatus.ident;
        }

        if (testIsNumber(constraintStatus.current_ttl)) {
            limitData.currentTtl = constraintStatus.current_ttl;

            if (constraintStatus.current_ttl > 0) {
                limitData.expiresAt = Date.now() + constraintStatus.current_ttl;
            }
        }

        if (testIsNumber(constraintStatus.ttl)) {
            limitData.ttl = constraintStatus.ttl;
        }

        if (testIsNumber(constraintStatus.current_value)) {
            limitData.currentValue = constraintStatus.current_value;
        }

        if (testIsNumber(constraintStatus.max_value)) {
            limitData.maxValue = constraintStatus.max_value;
        }

        if (testIsNonEmptyString(constraintStatus.triggered_by)) {
            limitData.triggeredBy = constraintStatus.triggered_by;
        }

        if (testIsBoolean(constraintStatus.hit)) {
            limitData.hit = constraintStatus.hit;
        }

        return limitData;
    }
}

export { LimitData };
