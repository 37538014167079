import { projectHelper } from './project-helper';
import { satGlobals } from '../../qaamgo/sat/helper/sat-globals';

var qgSetupReferralCookie = function () {
    const urlParams = new URLSearchParams(window.location.search);
    const refCode   = urlParams.get('ref_code');
    
    if (refCode) {
        document.cookie = projectHelper.getProjectName().toLowerCase() + '_REFERRAL=' + refCode + '; path=/; max-age=' + 604800 + '; secure; path=/;domain=' + satGlobals.getCookieDomain();
    }
};

qgSetupReferralCookie();
